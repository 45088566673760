
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        

































































































































































































































































/* stylelint-disable declaration-no-important, max-nesting-depth, no-descending-specificity */
@import '~vue-select/src/scss/vue-select';
@import '@shared/components/common/rates/index';

$vs-component-line-height: 1;
$vs-state-active-bg: $c-gray-lightest;
$vs-state-active-color: $black;
$vs-state-disabled-bg: $c-blue-dark;
$vs-border-width: 2px;
$vs-border-color: rgba($c-gray-lightest, 0.6);
$vs-selected-border-color: $white;
$vs-dropdown-bg: $c-white-dark;

.rates-fixe__table__row__cell {
  font-weight: 400 !important;
}

.rates-anchors {
  display: flex;
}

.rates-anchors__link {
  margin-right: 30px;
  color: $c-pink-medium;
}

.rates-hero__secondary {
  padding-top: 11rem !important;

  a {
    color: $c-pink-medium;
  }
}

.rates-prices {
  .rates-prices__table__cell {
    display: flex;
    flex-direction: column;
    padding: 4rem 2rem;
  }

  .generic-table__table__row {
    display: flex;
    align-items: center;
  }

  .rates-prices__label {
    margin-top: 1rem;
  }
}
